import * as React from "react";
import { ReactNode, useEffect, useState } from "react";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import { useAppDispatch } from "app/store";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { showMessage } from "app/store/fuse/messageSlice";
import { logoutUser, setUser } from "app/store/user/userSlice";
import { getFunctions, httpsCallable } from "firebase/functions";
import { db } from "app/configs/firebaseConfig";
import {
  doc,
  getDoc,
  onSnapshot,
} from "@firebase/firestore";
import { useSelector } from "react-redux";

const AuthContext = React.createContext({
  isAuthenticated: false,
  domainError: null,
  newAccountAlert: null,
  clearDomainError: () => {},
});
const selectProfiles = (state) => state.profiles.profiles;

type AuthProviderProps = { children: ReactNode };

function AuthProvider({ children }: AuthProviderProps) {
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  const [domainError, setDomainError] = useState(null);
  const [newAccountAlert, setnewAccountAlert] = useState(null);
  const dispatch = useAppDispatch();
  const profiles = useSelector(selectProfiles);
  const clearDomainError = () => {
    setDomainError(null), setnewAccountAlert(null);
  };

  useEffect(() => {
    const auth = getAuth();

    const verifyDomainUser = async (user) => {
      const checkDomainFunction = httpsCallable(getFunctions(), "verifyDomain");
      return await checkDomainFunction({
        email: user.email,
        uid: user.uid,
        name: user.displayName,
      });
    };

    const handleChangeAuth = async (user) => {
      if (!user) {
        console.log("No hay usuario autenticado.");
        dispatch(logoutUser());
        setWaitAuthCheck(false);
        return;
      }

      try {
        const resultVerificationDomain: any = await verifyDomainUser(user);
        if (resultVerificationDomain.data.isActive === false) {
          if (resultVerificationDomain.data.isNewAccount === true) {
            setnewAccountAlert(
              "Tu cuenta está pendiente de activación por parte de un administrador. Apreciamos tu paciencia y te notificaremos en cuanto puedas acceder."
            );
          } else {
            dispatch(
              showMessage({
                message:
                  "Cuenta desactivada. Por favor, contacta a tu administrador para más información.",
                variant: "error",
              })
            );
          }
          auth.signOut();
        } else if ((resultVerificationDomain as any).data.domainValid) {
          const userProfileRef = doc(db, "accounts", user.uid);
          const userProfileSnap = await getDoc(userProfileRef);

          if (userProfileSnap.exists()) {
            const userProfileData = userProfileSnap.data();
            const profileId = userProfileData.profile;
            const profileName =
              profiles.find((profile) => profile.id === profileId)?.name || "";
            const data = {
              uid: user.uid,
              displayName: user.displayName,
              photoURL: user.photoURL,
              email: user.email,
              activeLogisticCenter: userProfileData.activeLogisticCenter,
              profile: profileId,
              profileName: profileName,
              subsidiaries: userProfileData.subsidiaries,
              activeSubsidiary: userProfileData.activeSubsidiary,
            };
            dispatch(
              setUser({
                role: ["user"],
                data: data,
              })
            );

            const unsubscribeProfile = onSnapshot(userProfileRef, (doc) => {
              if (doc.exists()) {
                const updatedData = doc.data();
                const updatedProfileId = updatedData.profile;
                const updatedProfileName =
                  profiles.find((profile) => profile.id === updatedProfileId)
                    ?.name || "";
                data.profile = updatedProfileId;
                data.profileName = updatedProfileName;
                data.activeLogisticCenter = updatedData.activeLogisticCenter;
                data.activeSubsidiary = updatedData.activeSubsidiary;
                data.subsidiaries = updatedData.subsidiaries;
                dispatch(
                  setUser({
                    role: ["user"],
                    data: data,
                  })
                );
              }
            });
          } else {
            console.log("No se encontraron datos de perfil para el usuario.");
          }
        } else {
          setDomainError(
            "La cuenta seleccionada no está autorizada para el inicio de sesión debido a restricciones de dominio. Por favor, utiliza una cuenta válida."
          );
          auth.signOut();
        }
      } catch (error) {
        console.error("Error al verificar el usuario o el dominio: ", error);
        // Manejar el error como consideres necesario
      } finally {
        setWaitAuthCheck(false);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) =>
      handleChangeAuth(user)
    );
    return () => unsubscribe();
  }, [
    dispatch,
    profiles,
    setWaitAuthCheck,
    setnewAccountAlert,
    setDomainError,
  ]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!getAuth().currentUser,
        domainError,
        clearDomainError,
        newAccountAlert,
      }}
    >
      {waitAuthCheck ? <FuseSplashScreen /> : children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

export { useAuth, AuthProvider, AuthContext };

function deepEqual(x, y) {
  if (x === y) {
    return true;
  } else if (typeof x == "object" && x != null && typeof y == "object" && y != null) {
    if (Object.keys(x).length != Object.keys(y).length) return false;

    for (var prop in x) {
      if (y.hasOwnProperty(prop)) {
        if (!deepEqual(x[prop], y[prop])) return false;
      } else return false;
    }

    return true;
  } else {
    return false;
  }
}