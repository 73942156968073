import { lazy } from 'react';


const AuthManagement = lazy(() => import('./AuthManagement'));

/**
 * The Cost Center Page.
 */
const AuthManagementConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: 'configuracion/gestion-autorizaciones',
			element: <AuthManagement />
		}
	]
};
  
  export default AuthManagementConfig;