import { lazy } from 'react';


const TypeAndConcept = lazy(() => import('./TypeAndConcept'));

/**
 * The Logistic Center Page.
 */
const TypeAndConceptConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: 'mantenedores/tipo-concepto',
			element: <TypeAndConcept />
		}
	]
};
  
  export default TypeAndConceptConfig;