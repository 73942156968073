import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "app/store/user/userSlice";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import { useUserManagement } from "src/app/services/firebase/useUserManagement";
import { useAppDispatch } from "app/store";
import { showMessage } from "app/store/fuse/messageSlice";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FuseLoading from "@fuse/core/FuseLoading";

/*
This component is used to select the logistic center for the user.
First, it gets the user data from the store using the useSelector hook.
Then, use fetchSpecificSubsidiary to get the logistic centers from the user's subsidiary.
It renders a FormControl component with a Select component inside.
*/
export const LogisticCenterSelector = ({ setIsLoading, isLoading }) => {
  const user = useSelector(selectUser);
  const dispatch = useAppDispatch();
  const [selectedCenter, setSelectedCenter] = useState(
    user.data.activeLogisticCenter || ""
  );
  const { lcAccount, fetchSpecificSubsidiary, loading, updateActiveCenter } =
    useUserManagement();

  useEffect(() => {
    if (user.data.profileName) {
      fetchSpecificSubsidiary(user.data.activeSubsidiary);
    }
  }, [
    user.data.profileName,
    user.data.activeSubsidiary,
    fetchSpecificSubsidiary,
  ]);

  useEffect(() => {
    setSelectedCenter(user.data.activeLogisticCenter || "");
  }, [user.data.activeLogisticCenter]);

  const dispatchMessage = (message, variant) => {
    dispatch(
      showMessage({
        message: message,
        variant: variant,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      })
    );
  };

  const handleChange = async (event) => {
    setIsLoading(true);
    const newActiveCenter = event.target.value;
    setSelectedCenter(newActiveCenter);

    //Active center firebase update
    try {
      await updateActiveCenter({
        uid: user.data.uid,
        activeLogisticCenter: newActiveCenter,
      });
      dispatchMessage("Centro logístico seleccionado con éxito.", "success");
      setIsLoading(false);
    } catch (error) {
      console.error("Error al actualizar el centro logístico activo:", error);
      dispatchMessage(
        "Error al actualizar el centro logístico activo.",
        "error"
      );
      setIsLoading(false);
    }
  };

  if (loading || isLoading) {
    return (
      <Modal open={true} onClose={() => {}}>
        <Box
          className="flex items-center justify-center"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(255, 255, 255, 0.85)",
            borderRadius: "8px",
            boxShadow: "0 3px 7px rgba(0,0,0,0.3)",
            padding: "20px",
            zIndex: 1000,
          }}
        >
          <FuseLoading />
        </Box>
      </Modal>
    );
  }

  return (
    <div className="user relative flex flex-col items-center justify-center p-16 pb-14 pt-0 shadow-0">
      <FormControl fullWidth margin="normal">
        <InputLabel>Centro logístico</InputLabel>
        <Select
          value={selectedCenter}
          onChange={handleChange}
          label="Centro logístico"
        >
          {lcAccount.map((center) => (
            <MenuItem key={center.id} value={center.id}>
              {center.centro_logistico} {center.nombre_centro_logistico}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
