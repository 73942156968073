import { lazy } from 'react';


const Warehouses = lazy(() => import('./Warehouses'));

/**
 * The Logistic Center Page.
 */
const LogisticCentersConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: 'mantenedores/almacenes',
			element: <Warehouses />
		}
	]
};
  
  export default LogisticCentersConfig;