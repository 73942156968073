import { lazy } from 'react';


const LogisticsCenters = lazy(() => import('./LogisticsCenters'));

/**
 * The Logistic Center Page.
 */
const LogisticCentersConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: 'configuracion/centros-logisticos',
			element: <LogisticsCenters />
		}
	]
};
  
  export default LogisticCentersConfig;