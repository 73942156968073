import { lazy } from 'react';


const Monitor = lazy(() => import('./Monitor'));

/**
 * The Logistic Center Page.
 */
const MonitorConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: 'monitor',
			element: <Monitor />
		}
	]
};
  
  export default MonitorConfig;