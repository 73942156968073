import { lazy } from 'react';


const Users = lazy(() => import('./Users'));

/**
 * The Cost Center Page.
 */
const UsersConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: 'configuracion/usuarios',
			element: <Users />
		}
	]
};
  
  export default UsersConfig;