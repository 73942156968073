import { lazy } from 'react';

const Materials = lazy(() => import('./Materials'));

/**
 * The Logistic Center Page.
 */
const MaterialsConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: 'integracion-sap/materiales',
			element: <Materials />
		}
	]
};
  
  export default MaterialsConfig;