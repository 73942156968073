import { useState, useCallback } from "react";
import { db } from "app/configs/firebaseConfig";
import {
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
  orderBy,
  documentId,
  getDoc,
  runTransaction,
} from "firebase/firestore";

export const useUserManagement = () => {
  const [logisticsCenters, setLogisticCenters] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [loading, setLoading] = useState(true);

  // Logistics Centers by account
  const [lcAccount, setLcAccount] = useState([]);

  const [subsAccount, setSubsAccount] = useState([]);

  const fetchLogisticCenters = useCallback(async () => {
    setLoading(true);

    try {
      const q = query(
        collection(db, "logisticsCenters"),
        where("deletedAt", "==", null),
        orderBy("centro_logistico", "desc")
      );
      const querySnapshot = await getDocs(q);
      const centers = [];
      querySnapshot.forEach((doc) => {
        centers.push({ id: doc.id, ...doc.data() });
      });
      setLogisticCenters(centers);
    } catch (error) {
      console.error("Error fetching logistic centers:", error);
    }
    setLoading(false);
  }, []);

  const fetchSpecificLogisticCenters = useCallback(async (centerIds) => {
    if (centerIds.length === 0) {
      return;
    }
    setLoading(true);
    try {
      const q = query(
        collection(db, "logisticsCenters"),
        where(documentId(), "in", centerIds),
        where("deletedAt", "==", null),
        orderBy("centro_logistico", "desc")
      );
      const querySnapshot = await getDocs(q);
      const centers = [];
      querySnapshot.forEach((doc) => {
        centers.push({ id: doc.id, ...doc.data() });
      });
      setLogisticCenters(centers);
    } catch (error) {
      console.error("Error fetching specific logistic centers:", error);
    }
    setLoading(false);
  }, []);

  const fetchProfiles = useCallback(async () => {
    setLoading(true);
    try {
      const q = query(
        collection(db, "profiles"),
        where("deletedAt", "==", null)
      );
      const querySnapshot = await getDocs(q);
      const profiles = [];
      querySnapshot.forEach((doc) => {
        profiles.push({ id: doc.id, ...doc.data() });
      });
      setProfiles(profiles);
    } catch (error) {
      console.error("Error fetching profiles:", error);
    }
    setLoading(false);
  }, []);

  const fetchSubsidiaries = useCallback(async () => {
    setLoading(true);
    try {
      const q = query(
        collection(db, "subsidiaries"),
        where("deletedAt", "==", null)
      );
      const querySnapshot = await getDocs(q);
      const subsidiaries = [];
      querySnapshot.forEach((doc) => {
        subsidiaries.push({ id: doc.id, ...doc.data() });
      });
      setSubsidiaries(subsidiaries);
    } catch (error) {
      console.error("Error fetching profiles:", error);
    }
    setLoading(false);
  }, []);

  const fetchSpecificLcAccount = useCallback(async (centerIds) => {
    if (centerIds.length === 0) {
      return;
    }
    setLoading(true);
    try {
      const q = query(
        collection(db, "logisticsCenters"),
        where(documentId(), "in", centerIds),
        where("deletedAt", "==", null),
        orderBy("centro_logistico", "desc")
      );
      const querySnapshot = await getDocs(q);
      const centers = [];
      querySnapshot.forEach((doc) => {
        centers.push({ id: doc.id, ...doc.data() });
      });
      setLcAccount(centers);
    } catch (error) {
      console.error("Error fetching specific logistic centers:", error);
    }
    setLoading(false);
  }, []);

  // Function to fetch specific logistic center
  const fetchSpecificSubsidiary = useCallback(
    async (subsidiaryId) => {
      if (subsidiaryId === "") {
        return;
      }
      setLoading(true);
      try {
        await runTransaction(db, async (transaction) => {
          const docRef = doc(db, "subsidiaries", subsidiaryId);
          const docSnap = await transaction.get(docRef);

          if (docSnap.exists()) {
            const logisticsCenters = docSnap.data().logisticsCenters || [];
            if (logisticsCenters.length > 0) {
              fetchSpecificLcAccount(logisticsCenters);
            }
          } else {
            console.log("No such document!");
          }
        });
      } catch (error) {
        console.error("Error fetching specific subsidiary:", error);
      }
      setLoading(false);
    },
    [fetchSpecificLcAccount]
  );

  // Function to fetch user subsidiaries
  const fetchUserSubsidiaries = useCallback(async (subsidiaries) => {
    if (subsidiaries.length === 0) {
      return;
    }
    setLoading(true);
    try {
      const q = query(
        collection(db, "subsidiaries"),
        where(documentId(), "in", subsidiaries),
        where("deletedAt", "==", null)
      );
      const querySnapshot = await getDocs(q);
      const fetchedSubsidiaries = [];
      querySnapshot.forEach((doc) => {
        fetchedSubsidiaries.push({ id: doc.id, ...doc.data() });
      });
      setSubsAccount(fetchedSubsidiaries);
    } catch (error) {
      console.error("Error fetching user subsidiaries:", error);
    }
    setLoading(false);
  }, []);

  // Function to update the profile and assign logistics centers of a user account in firestore
  const updateUserProfile = async ({ uid, profile, subsidiaries }) => {
    try {
      const userDocRef = doc(db, "accounts", uid);
      await updateDoc(userDocRef, { profile, subsidiaries });
      return { success: true };
    } catch (error) {
      console.error("Error updating user profile:", error);
      return { success: false, error };
    }
  };

  const updateActiveCenter = async ({ uid, activeLogisticCenter }) => {
    try {
      const userDocRef = doc(db, "accounts", uid);
      await updateDoc(userDocRef, { activeLogisticCenter });
      return { success: true };
    } catch (error) {
      console.error("Error updating active center:", error);
      return { success: false, error };
    }
  };

  const updateActiveSubsidiary = async ({ uid, activeSubsidiary }) => {
    try {
      const userDocRef = doc(db, "accounts", uid);
      const subsidiaryDocRef = doc(db, "subsidiaries", activeSubsidiary);
      const subsidiaryDoc = await getDoc(subsidiaryDocRef);
      if (!subsidiaryDoc.exists()) {
        throw new Error("Subsidiary document does not exist!");
      }
      const logisticsCenters = subsidiaryDoc.data().logisticsCenters || [];
      const activeLogisticCenter =
        logisticsCenters.length > 0 ? logisticsCenters[0] : null;

      await updateDoc(userDocRef, { activeSubsidiary, activeLogisticCenter });

      return { success: true };
    } catch (error) {
      console.error("Error updating active center:", error);
      return { success: false, error };
    }
  };

  return {
    logisticsCenters,
    profiles,
    subsidiaries,
    lcAccount,
    loading,
    fetchLogisticCenters,
    fetchSpecificLogisticCenters,
    fetchSpecificSubsidiary,
    fetchUserSubsidiaries,
    subsAccount,
    fetchProfiles,
    fetchSubsidiaries,
    updateUserProfile,
    updateActiveCenter,
    updateActiveSubsidiary,
  };
};
