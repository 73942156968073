import { lazy } from 'react';

const MaterialExpansion = lazy(() => import('./MaterialExpansion'));

/**
 * The Logistic Center Page.
 */
const MaterialExpansionConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: 'integracion-sap/ampliacion-materiales',
			element: <MaterialExpansion />
		}
	]
};
  
  export default MaterialExpansionConfig;