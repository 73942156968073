import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Profile {
  id: string;
  // ... other properties
}

interface ProfilesState {
  profiles: Profile[];
  loading: boolean;
  error: string | null;
}

const initialState: ProfilesState = {
  profiles: [],
  loading: false,
  error: null,
};

const profilesSlice = createSlice({
  name: "profiles",
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },
    profilesLoaded(state, action: PayloadAction<Profile[]>) {
      state.profiles = action.payload;
      state.loading = false;
    },
    profilesLoadingFailed(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { startLoading, profilesLoaded, profilesLoadingFailed } =
  profilesSlice.actions;

export const selectProfiles = (state) => state.profiles.profiles;

export default profilesSlice.reducer;
