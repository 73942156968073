import { lazy } from 'react';


const ErrorsTypes = lazy(() => import('./ErrorsTypes'));

/**
 * The Cost Center Page.
 */
const ErrorsTypesConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: 'configuracion/tipos-de-errores',
			element: <ErrorsTypes />
		}
	]
};
  
  export default ErrorsTypesConfig;