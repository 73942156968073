import { lazy } from 'react';


const CostCenters = lazy(() => import('./CostCenters'));

/**
 * The Cost Center Page.
 */
const CostCentersConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: 'mantenedores/centros-costo',
			element: <CostCenters />
		}
	]
};
  
  export default CostCentersConfig;