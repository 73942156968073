import FuseUtils from "@fuse/utils";
import FuseLoading from "@fuse/core/FuseLoading";
import { Navigate } from "react-router-dom";
import settingsConfig from "app/configs/settingsConfig";
import { FuseRouteConfigsType, FuseRoutesType } from "@fuse/utils/FuseUtils";
import SignInConfig from "../main/sign-in/SignInConfig";
import SignUpConfig from "../main/sign-up/SignUpConfig";
import SignOutConfig from "../main/sign-out/SignOutConfig";
import Error404Page from "../main/404/Error404Page";
import ExampleConfig from "../main/example/ExampleConfig";
import * as Maintainers from "../main/modules/maintainers";
import * as Configs from "../main/modules/configurations";
import * as Integration from "../main/modules/sap-integration";
import MonitorConfig from "../main/modules/monitor/MonitorConfig";

import ProjectDashboardAppConfig from "../main/project/ProjectDashboardAppConfig";

const routeConfigs: FuseRouteConfigsType = [
  ...Object.values(Maintainers),
  ...Object.values(Configs),
  ...Object.values(Integration),
  MonitorConfig,
  ExampleConfig,
  SignOutConfig,
  SignInConfig,
  SignUpConfig,
  ProjectDashboardAppConfig,
];

/**
 * The routes of the application.
 */
const routes: FuseRoutesType = [
  ...FuseUtils.generateRoutesFromConfigs(
    routeConfigs,
    settingsConfig.defaultAuth
  ),
  {
    path: "/",
    element: <Navigate to="/dashboards/project" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: "loading",
    element: <FuseLoading />,
  },
  {
    path: "404",
    element: <Error404Page />,
  },
  {
    path: "*",
    element: <Error404Page />,
    auth: settingsConfig.defaultAuth,
  },
];

export default routes;
