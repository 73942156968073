import i18next from "i18next";
import { FuseNavigationType } from "@fuse/core/FuseNavigation/types/FuseNavigationType";
import ar from "./navigation-i18n/ar";
import en from "./navigation-i18n/en";
import tr from "./navigation-i18n/tr";

i18next.addResourceBundle("en", "navigation", en);
i18next.addResourceBundle("tr", "navigation", tr);
i18next.addResourceBundle("ar", "navigation", ar);

/**
 * The navigationConfig object is an array of navigation items for the Fuse application.
 */
const navigationConfig: FuseNavigationType = [
  {
    id: "example-component0",
    title: "Dashboard",
    translate: "Dashboard",
    type: "item",
    icon: "feather:activity",
    url: "dashboards/project",
  },
  {
    id: "maintainers",
    title: "Mantenedores",
    translate: "Mantenedores",
    type: "collapse",
    icon: "feather:database",
    children: [
      {
        id: "type-and-concept",
        title: "Tipo y Concepto",
        type: "item",
        url: "/mantenedores/tipo-concepto",
      },
      {
        id: "warehouses",
        title: "Almacenes",
        type: "item",
        url: "/mantenedores/almacenes",
      },
      {
        id: "material-homologation",
        title: "Homologación de Materiales",
        type: "item",
        url: "/mantenedores/homologacion-materiales",
      },
      {
        id: "material-exception",
        title: "Excepción de Materiales",
        type: "item",
        url: "/mantenedores/excepcion-materiales",
      },
      {
        id: "cost-centers",
        title: "Centros de Costo",
        type: "item",
        url: "/mantenedores/centros-costo",
      },
    ],
  },
  {
    id: "monitor",
    title: "Monitor",
    translate: "Monitor",
    type: "item",
    icon: "feather:monitor",
    url: "monitor",
  },
  {
    id: "quadratures",
    title: "Cuadraturas",
    translate: "Cuadraturas",
    type: "item",
    icon: "material-twotone:inventory",
    url: "",
  },
  {
    id: "integration",
    title: "Integración SAP",
    //translate: "Integración SAP",
    type: "collapse",
    icon: "material-outline:miscellaneous_services",
    children: [
      {
        id: "material-expansion",
        title: "Ampliación de Materiales",
        type: "item",
        url: "/integracion-sap/ampliacion-materiales",
      },
      {
        id: "materials",
        title: "Materiales",
        type: "item",
        url: "/integracion-sap/materiales",
      },
    ],
  },
  {
    id: "configuration",
    title: "Configuración",
    translate: "Configuración",
    type: "collapse",
    icon: "feather:settings",
    children: [
      {
        id: "users",
        title: "Usuarios",
        type: "item",
        url: "/configuracion/usuarios",
      },
      {
        id: "gestion-autorizaciones",
        title: "Gestión de autorizaciones",
        type: "item",
        url: "/configuracion/gestion-autorizaciones",
      },
      {
        id: "logistics-centers",
        title: "Centros Logísticos",
        type: "item",
        url: "/configuracion/centros-logisticos",
      },
      {
        id: "subsidiaries",
        title: "Filiales",
        type: "item",
        url: "/configuracion/filiales",
      },
      {
        id: "errors-types",
        title: "Tipos de clasificación de líneas",
        type: "item",
        url: "/configuracion/tipos-de-errores",
      },
      {
        id: "logs",
        title: "Logs",
        type: "item",
        url: "/configuracion/logs",
      },
    ],
  },
];

export default navigationConfig;
