import { lazy } from 'react';


const MaterialHomologation = lazy(() => import('./MaterialHomologation'));

/**
 * The Logistic Center Page.
 */
const MaterialHomologationConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: 'mantenedores/homologacion-materiales',
			element: <MaterialHomologation />
		}
	]
};
  
  export default MaterialHomologationConfig;