import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useContext, useEffect } from "react";
import { UserType } from "app/store/user";
import jwtService from "../../auth/services/jwtService";
import GoogleButton from "react-google-button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { AuthContext } from "src/app/auth/AuthContext";

// import { Controller } from 'react-hook-form';
// import Checkbox from '@mui/material/Checkbox';
// import FormControl from '@mui/material/FormControl';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import TextField from '@mui/material/TextField';
// import { InferType } from 'yup';
// import _ from '@lodash';
// import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
// import AvatarGroup from '@mui/material/AvatarGroup';
// import Avatar from '@mui/material/Avatar';

//Google Services
import { signInWithGoogle } from "../../auth/services/authServices";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { useAppDispatch } from "app/store";
import { showMessage } from "app/store/fuse/messageSlice";

/**
 * Form Validation Schema
 */
// const schema = yup.object().shape({
//   email: yup
//     .string()
//     .email("You must enter a valid email")
//     .required("You must enter a email"),
//   password: yup
//     .string()
//     .required("Please enter your password.")
//     .min(4, "Password is too short - must be at least 4 chars."),
//   remember: yup.boolean(),
// });

// const defaultValues = {
//   email: "",
//   password: "",
//   remember: true,
// };

/**
 * The sign in page.
 */
function SignInPage() {
  // const { control, formState, handleSubmit, setError, setValue } = useForm({
  // 	mode: 'onChange',
  // 	defaultValues,
  // 	resolver: yupResolver(schema)
  // });

  // const { isValid, dirtyFields, errors } = formState;

  // useEffect(() => {
  // 	setValue('email', 'admin@fusetheme.com', { shouldDirty: true, shouldValidate: true });
  // 	setValue('password', 'admin', { shouldDirty: true, shouldValidate: true });
  // }, [setValue]);

  // function onSubmit(e: { preventDefault: () => void; }) {
  // 	e.preventDefault();
  // 	console.log("onSubmit")
  // 	jwtService
  // 		.signInWithEmailAndPassword("admin@fusetheme.com", "admin")
  // 		.then((user: UserType) => {
  // 			// eslint-disable-next-line no-console
  // 			console.info(user);

  // 			// No need to do anything, user data will be set at app/auth/AuthContext
  // 		})
  // 		.catch((_errors: { type: 'email' | 'password' | `root.${string}` | 'root'; message: string }[]) => {
  // 			_errors.forEach((error) => {
  // 				setError(error.type, {
  // 					type: 'manual',
  // 					message: error.message
  // 				});
  // 			});
  // 		});
  // }
  // const navigate = useNavigate();
  // const auth = getAuth();

  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       navigate("/dashboards/project");
  //     }
  //   });

  //   return () => unsubscribe();
  // }, [navigate, auth]);

  /**
   * The domain error dialog.
   */
  const { domainError, clearDomainError, newAccountAlert } = useContext(AuthContext);

  useEffect(() => {
    if (domainError || newAccountAlert) {
    }
  }, [domainError, newAccountAlert]);

  const handleClose = () => {
    clearDomainError();
  };

  /**
   * Handles the Google sign in.
   */
  const dispatch = useAppDispatch();
  const dispatchMessage = (message, variant) => {
    dispatch(
      showMessage({
        message: message,
        variant: variant,
        autoHideDuration: 15000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      })
    );
  };
  const handleGoogleSignIn = () => {
    signInWithGoogle()
      .then((res) => {
        if (res.error) {
          throw new Error(res.error); // Lanza el error para capturarlo en el .catch()
        }
      })
      .catch((error) => {
        const errorMessage = (error.message.search('disabled')) ? "La cuenta de usuario está desactivada." : "Ha ocurrido un error.";
        dispatchMessage(`Fallo en el inicio de sesión: ${errorMessage} Por favor, póngase en contacto con el administrador.`, "error");
        console.error("Error en el inicio de sesión con Google:", error);
      });
  };
  

  return (
    <div className="flex min-w-0 flex-1 flex-col items-center sm:flex-row sm:justify-center md:items-start md:justify-start">
      <Paper className="h-full w-full px-16 py-8 ltr:border-r-1 rtl:border-l-1 sm:h-auto sm:w-auto sm:rounded-2xl sm:p-48 sm:shadow md:flex md:h-full md:w-1/2 md:items-center md:justify-end md:rounded-none md:p-64 md:shadow-none">
        <div className="mx-auto w-full max-w-320 sm:mx-0 sm:w-320">
          <img src="assets/images/logo/logo.svg" alt="logo" />

          <Typography className="mt-32 text-4xl font-extrabold leading-tight tracking-tight">
            Comencemos
          </Typography>
          <GoogleButton
            onClick={handleGoogleSignIn}
            label="Iniciar sesión con Google"
          />
        </div>
      </Paper>

      <Box
        className="relative hidden h-full flex-auto items-center justify-center overflow-hidden p-64 md:flex lg:px-112"
        sx={{ backgroundColor: "primary.main" }}
      >
        <svg
          className="pointer-events-none absolute inset-0"
          viewBox="0 0 960 540"
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMax slice"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Box
            component="g"
            sx={{ color: "primary.light" }}
            className="opacity-20"
            fill="none"
            stroke="currentColor"
            strokeWidth="100"
          >
            <circle r="234" cx="196" cy="23" />
            <circle r="234" cx="790" cy="491" />
          </Box>
        </svg>
        <Box
          component="svg"
          className="absolute -right-64 -top-64 opacity-20"
          sx={{ color: "primary.light" }}
          viewBox="0 0 220 192"
          width="220px"
          height="192px"
          fill="none"
        >
          <defs>
            <pattern
              id="837c3e70-6c3a-44e6-8854-cc48c737b659"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect x="0" y="0" width="4" height="4" fill="currentColor" />
            </pattern>
          </defs>
          <rect
            width="220"
            height="192"
            fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
          />
        </Box>

        <div className="relative z-10 w-full max-w-2xl">
          <div className="text-7xl font-bold leading-none text-gray-100">
            <div>Controlador de</div>
            <div>Integraciones</div>
          </div>
          <div className="mt-24 text-lg leading-6 tracking-tight text-gray-400">
            Cuadratura de movimientos de inventario entre HIS Legados y SAP
          </div>
        </div>
      </Box>
      <Dialog
        open={!!domainError}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { width: "400px", maxWidth: "none" },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Error de Inicio de Sesión"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {domainError || "Ha ocurrido un error."}{" "}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={!!newAccountAlert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { width: "400px", maxWidth: "none" },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {"¡Registro exitoso!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {newAccountAlert || ""}{" "}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SignInPage;
