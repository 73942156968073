import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "app/store/user/userSlice";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import { useUserManagement } from "src/app/services/firebase/useUserManagement";
import { useAppDispatch } from "app/store";
import { showMessage } from "app/store/fuse/messageSlice";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FuseLoading from "@fuse/core/FuseLoading";

/*
This component is used to select the user's subsidiary.
First, it gets the user data from the store using the useSelector hook.
Then, it returns the list of the user's subsidiaries.
*/
export const SubsidiarySelector = ({ setIsLoading, isLoading }) => {
  const user = useSelector(selectUser);
  const dispatch = useAppDispatch();
  const [selectedSubsidiary, setSelectedSubsidiary] = useState(
    user.data.activeSubsidiary || ""
  );

  const {
    subsAccount,
    fetchUserSubsidiaries,
    loading,
    updateActiveSubsidiary,
  } = useUserManagement();

  useEffect(() => {
    if (user.data.profileName && user.data.subsidiaries) {
      fetchUserSubsidiaries(user.data.subsidiaries);
    }
  }, [fetchUserSubsidiaries, user.data.profileName, user.data.subsidiaries]);

  useEffect(() => {
    setSelectedSubsidiary(user.data.activeSubsidiary || "");
  }, [user.data.activeSubsidiary]);

  const dispatchMessage = (message, variant) => {
    dispatch(
      showMessage({
        message: message,
        variant: variant,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      })
    );
  };

  const handleChange = async (event) => {
    setIsLoading(true);
    const activeSubsidiary = event.target.value;
    setSelectedSubsidiary(activeSubsidiary);

    //Active center firebase update
    try {
      await updateActiveSubsidiary({
        uid: user.data.uid,
        activeSubsidiary: activeSubsidiary,
      });
      dispatchMessage("Filial seleccionada con éxito.", "success");
      setIsLoading(false);
    } catch (error) {
      console.error("Error al seleccionar filial:", error);
      dispatchMessage("Error al seleccionar filial.", "error");
      setIsLoading(false);
    }
  };

  if (loading || isLoading) {
    return (
      <Modal open={true} onClose={() => {}}>
        <Box
          className="flex items-center justify-center"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(255, 255, 255, 0.85)",
            borderRadius: "8px",
            boxShadow: "0 3px 7px rgba(0,0,0,0.3)",
            padding: "20px",
            zIndex: 1000,
          }}
        >
          <FuseLoading />
        </Box>
      </Modal>
    );
  }

  return (
    <div className="user relative flex flex-col items-center justify-center p-16 pb-0 pt-0 shadow-0">
      <FormControl fullWidth margin="normal">
        <InputLabel>Filial</InputLabel>
        <Select
          value={selectedSubsidiary}
          onChange={handleChange}
          label="Filial"
        >
          {subsAccount.map((center) => (
            <MenuItem key={center.id} value={center.id}>
              {center.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
