import { lazy } from 'react';


const LogsMonitor = lazy(() => import('./LogsMonitor'));

/**
 * The LogsMonitor Page.
 */
const LogsMonitorConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: 'configuracion/logs',
			element: <LogsMonitor />
		}
	]
};
  
  export default LogsMonitorConfig;