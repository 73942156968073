import { lazy } from 'react';


const MaterialException = lazy(() => import('./MaterialException'));

/**
 * The Logistic Center Page.
 */
const MaterialExceptionConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: 'mantenedores/excepcion-materiales',
			element: <MaterialException />
		}
	]
};
  
  export default MaterialExceptionConfig;