import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { db } from 'app/configs/firebaseConfig';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { profilesLoaded } from 'app/store/profilesSlice';

export const useProfiles = () => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    // Defines the query to get all undeleted profiles
    const q = query(collection(db, "profiles"), where("deletedAt", "==", null));

    // Listen real-time to the query
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const profiles = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Dispatch the action to update the Redux store
      dispatch(profilesLoaded(profiles));
    });

    // Clear the subscription when the component using this hook is unmounted
    return () => unsubscribe();
  }, [dispatch]);
};
