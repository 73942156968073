import { lazy } from 'react';

const Subsidiaries = lazy(() => import('./Subsidiaries'));

/**
 * The Logistic Center Page.
 */
const SubsidiariesConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: 'configuracion/filiales',
			element: <Subsidiaries />
		}
	]
};
  
  export default SubsidiariesConfig;